
<div *ngIf="loginDisplay">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <a class="navbar-brand mx-auto" href="#"><img src="assets/images/logo.png" alt="" class="img-fluid" /></a>
        <a class="Logout"><mat-icon  (click)="logout()">logout</mat-icon></a>
        
        
        <!-- <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item active"> <a class="nav-link" href="#"><span class="sr-only">(current)</span></a> </li>
            <li class="nav-item"> <a class="nav-link" href="#"></a> </li>
            <li class="nav-item dropdown">
              
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="#">Action</a> <a class="dropdown-item" href="#"></a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">Something else here</a> 
              </div>
            </li> 
          </ul> 
        </div> -->
      </nav>
      <header> 
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 text-center">
                  <h1 class="title text-white m-4">Mobile App Distributions</h1>                 
                      <p><img src="./assets/pcs/img/ios-marketing.png" alt="" class="img-fluid mt-5" onclick="window.location.href='/pcs'" /></p> 
                  </div>
            </div>     
        </div>
      </header>
      
      <!-- jQuery (necessary for Bootstrap's JavaScript plugins) --> 
      <script src="../../../../Desktop/boot/Two/js/jquery-3.4.1.min.js"></script> 
      <!-- Include all compiled plugins (below), or include individual files as needed --> 
      <script src="../../../../Desktop/boot/Two/js/popper.min.js"></script> 
      <script src="../../../../Desktop/boot/Two/js/bootstrap-4.4.1.js"></script>
      
</div>




