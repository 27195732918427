<!DOCTYPE html>
<html lang="en">
<head>
<!--  <base href="./">-->
<meta charset="utf-8">
<meta http-equiv="X-UA-Compatible" content="IE=edge">
<meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no">
<title>PCS</title>
<link rel="icon" href="./assets/pcs/img/favicon.png" sizes="32x32" type="image/png">
<!-- Styles -->
<!-- <link href="./assets/pcs/css/bootstrap.min.css" rel="stylesheet">
<link href="./assets/pcs/css/font-awesome.min.css" rel="stylesheet"> -->
<!-- <link href="css/style.css" rel="stylesheet">  -->
</head> 

<body class="bg-light">
<header class="app-header justify-content-center navbar"> 
  <a class="navbar-brand" href="javascript:void(0)"> <h3 class="mt-2" style="color: black;"> PCS Reciept</h3> </a> 
  <div class="Logout"><mat-icon  (click)="logout()">logout</mat-icon></div>  
</header>
<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-4">
      <div class="card"> <img class="card-img-top" src="./assets/pcs/img/ios-marketing.png" alt="PCS" />
        <div class="card-body">
          <h5 class="card-title">PCS</h5>
          <p class="badge badge-secondary">v2.1</p><br>

          <div><a href="./assets/pcs/details/" class="btn btn-primary btn-block btn-success"><i class="fa fa-apple"></i> iOS - View Details</a>
<a href="./assets/pcs/details/pcs.apk" class="btn btn-primary btn-block btn-success android-btn"><i class="fa fa-android"></i> Android</a></div></div>
      </div>
    </div>
  </div>
</div>
<footer class="app-footer"> &copy; 2023 Bridgetree Inc. All Rights Reserved. </footer>
<!-- JS and necessary plugins--> 
<script src="js/jquery.min.js"></script> 
<script src="js/popper.min.js"></script> 
<script src="js/bootstrap.min.js"></script>
</body>
</html>
<html><head><META HTTP-EQUIV="Content-Type" CONTENT="text/html; charset=utf-8"><meta name="Robots" content="NOINDEX " /></head><body></body>
                <script type="text/javascript">
                 var gearPage = document.getElementById('GearPage');
                 if(null != gearPage)
                 {
                     gearPage.parentNode.removeChild(gearPage);
                     document.title = "Error";
                 }
                 </script>
                 </html>
