import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MsalGuard } from '@azure/msal-angular';
import { PcsComponent } from './pcs/pcs.component';
import { PageNotFoundComponent } from './pageNotFound/pageNotFound.component';

const routes: Routes = [
 {
    // Needed for hash routing
    path: 'code',
    component: HomeComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'pcs',
    component: PcsComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    // Needed for Error routing
    path: '**', 
    pathMatch: 'full', 
    component: PageNotFoundComponent
  }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
